import React from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./Router";
import "./App.css"
import axios from "axios";
import { useState,useEffect } from "react";
import Global from "./utils/Global";

class App extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      main:[],
      team:{},
      fea:{},
      car:{}
    }
  }

  componentDidMount() {
  axios.defaults.headers = {'Content-Type': 'application/x-www-form-urlencoded'}; 
 
    axios({
      method: 'post',
      url: 'https://lawex.in/newappapi/index.php/landingpage',
      data: "appid=lawex"
      //config: headers
  }).then((res)=>{
    let a = res.data.data;
    for(let i=0;i<a.length;i++){
      if(a[i].code == 'CARO'){
        let x = {}
        x[a[i]['field']] = a[i]['value'];
        x['data'] = a[i]['car'];
        this.state.car = x; 
      }else if(a[i].code == 'TEAM'){
        let x = {}
        x[a[i]['field']] = a[i]['value'];
        x['data'] = a[i]['team'];
        this.state.team = x; 
      }else if(a[i].code == 'FEA'){
        let x = {}
        x[a[i]['field']] = a[i]['value'];
        x['data'] = a[i]['fea'];
        this.state.fea = x; 
      }else{
        let x={};
        x[a[i]['field']] = a[i]['value'];
        this.state.main.push(x);
      }

    }
    console.log(this.state)
   
  }).catch((err)=>{console.log(err)
  }).finally (()=>{})
 
}
  render() {
    // window.onpopstate = ()=>{
    //   console.log("global launched",Global.isLaunched)
    //   if(Global.isLaunched){
    //     Global.setIsLaunched(false)
    //   }else{
    //     Global.setIsLaunched(true)
    //   }
    // }
  return (
    <div>
      <BrowserRouter>
        <Router/>
      </BrowserRouter>
    </div>
  );
  }
}

export default App;
