import { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";

const routes = [
    {
      path: ["/", "/home"],
      exact: true,
      component: "Home",
    },
    {
      path: ["/students"],
      exact: true,
      component: "Students",
    },
    {
        path: ["/lawyers"],
        exact: true,
        component: "Lawyers",
    },
    {
        path: ["/clients"],
        exact: true,
        component: "Clients",
    },
    {
        path: ["/webinar"],
        exact: true,
        component: "Webinar",
    },
    {
        path: ["/seminar"],
        exact: true,
        component: "Seminar",
    },
    {
      path: ["/privacy"],
      exact: true,
      component: "Privacy",
    },
    {
      path: ["/terms"],
      exact: true,
      component: "Terms",
    },
    {
      path: ["/contact"],
      exact: true,
      component: "Contact",
    },
    {
      path:['/test'],
      exact:true,
      component:"Test"
    }
  ];


const Router = () => {
    return (
      <Suspense fallback={null}>
        <Switch>
          {routes.map((routeItem) => {
            return (
              <Route
                key={routeItem.component}
                path={routeItem.path}
                exact={routeItem.exact}
                component={lazy(() => import(`./pages/${routeItem.component}`))}
              />
            );
          })}
        </Switch>
      </Suspense>
    );
  };
  
  export default Router;